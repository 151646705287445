import React from "react";
import { useTranslation } from "react-i18next";

import './easy-icon.scss'

import A from "./img/awards.svg"
import F from "./img/file.svg"
import S from "./img/settings.svg"

const iconArray =[F,A,S]

export function EasyIcon(){
    const {t} = useTranslation();
    return(
        iconArray.map((el,index)=>{
            return(
                <div className="easy-icon d-flex" key={index}>
                    <div className="easy-icon_img d-flex_column">
                        <img src={el} alt={index} />
                    </div>
                    <div className="easy-icon_text">
                        <h3>{t(`content.easy-and-funct.icon.${index}.0`)}</h3>
                        <h5>{t(`content.easy-and-funct.icon.${index}.1`)}</h5>
                    </div>
                </div>
            )
        })
    )
}