import React from "react";
import { useTranslation } from "react-i18next";

import "./ready-begin.scss"

import L from "./img/layer1.svg"
import Logo from "./img/lmachine-logo.svg"

const ArrayIcon = [L,Logo]

export function ReadyBegin(){
    const {t} = useTranslation();

    return(
        <div className="ready-begin case d-flex">
            <div className="ready-begin_info">
                <h1>{t(`why-lmachine.ready-begin.title`)}</h1>
                <div className="dash"></div>
                <h5>{t(`why-lmachine.ready-begin.info`)}</h5>
            </div>
            <div className="ready-begin_case">
                {
                    ArrayIcon.map((el,index)=>{
                        return(
                            <div className="box-info0 box-info" key={index}>
                                <div className="box-info_img">
                                    <img className="d-flex_column" style={{width:42,height:42}} src={el} alt="" />
                                </div>
                                <h4 className={`box-info0_title${index}`}>{t(`why-lmachine.ready-begin.case.${index}.0`)}</h4>
                                <h5 className={`box-info0_text${index}`}>{t(`why-lmachine.ready-begin.case.${index}.1`)}</h5>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}