import React from "react";
import { useTranslation } from 'react-i18next';

import "./pop-up-button.scss"

export function ButtonHeder(){
    const { t } = useTranslation();
    return(
        <button className="button-header" id="pop-up-header">{t('header.button')}</button>
    )
}

export function ButtonContact(){
    const { t } = useTranslation();
    return(
        <button className="button-content" id="pop-up-contact">{t('intro-content.button')}</button>
    )
}