import React from "react";
import { useTranslation } from "react-i18next";
import { EasyIcon } from "./icon/easy-icon";

import "./easy-and-functional.scss"

import ImgContent from "./img/Group_1017.svg"

export function EasyAndFunct(){
    const {t} = useTranslation()

    return(
        <div className="easy-and-funct case d-flex">
            <img className="easy-and-funct_img-content" src={ImgContent} alt="content" />
            <div className="text-box">
                <h2>{t("content.easy-and-funct.subtitle")}</h2>
                <h1>{t("content.easy-and-funct.title")}</h1>
                <div className="dash"></div>
                <h5>{t("content.easy-and-funct.info")}</h5>
                <div className="icon-box">
                    <EasyIcon></EasyIcon>
                </div>
            </div>
        </div>
    )
}

