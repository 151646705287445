import React from "react";
import { useTranslation } from "react-i18next";
import { InfoCase } from "./info-case/info-case";
import { ReadyBegin } from "./ready-begin/ready-begin";

import "./why-lmachine.scss"

export function WhyLmachine(){
    const {t} = useTranslation();
    return(
        <>
            <div className="bg-botton"></div>
            <div className="d-flex_column why-lmachine case">
                <h2>{t("why-lmachine.subtitle")}</h2>
                <h1>{t("why-lmachine.title")}</h1>
                <div className="dash"></div>
                <InfoCase></InfoCase>
                <ReadyBegin></ReadyBegin>
            </div>
        </>
    )
}

