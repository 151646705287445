import React, { useEffect, useState } from "react";
import { Lang, useFormInputValidation } from "react-form-input-validation";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import "./pop-up-modail.scss"
import "./form/form.scss";
import { sendEmail } from "./form/send";
import { CrossButton } from "../button/cross-button/cross-button";


import LogoText from "./img/image.svg"
import FormImg from "./img/Group_girl1.png"



const switchOn = (el,set,prop)=>{
    document.querySelector(`#${el}`).addEventListener("click",()=>{
        set(!prop)
    })
}


export function PopUpModailForm(){
    const [on, setOn] = useState(false)
    const {t} = useTranslation()
    const [actForm, setActForm] = useState(false)
    const [actAns, setActAns] = useState(false)
    const [actReCa, setActReCa] = useState(false)
    const [fields,errors, form] = useFormInputValidation(
        {
            name: "",
            email: "",
            message:""
        },
        {
            name: "required|username_available",
            email: "required|email",
            message: "required|max:500",
        }
    );

    useEffect(()=>{
        
        switchOn("pop-up-contact",setOn,on)
        switchOn("cross-button",setOn,on)
        switchOn("pop-up-header",setOn,on)
    },)

    useEffect(() => {
        form.registerAsync("username_available", function (
            username,
            attribute,
            req,
            passes
        ) {
        setTimeout(() => {
            if (username === "foo")
            passes(false, "Username has already been taken.");
            // if username is not available
            else passes();
        }, 1000);
        });
    }, []);

    if(localStorage.getItem("i18nextLng") === "uk"){
        form.useLang(Lang.uk);
    }else{
        form.useLang(Lang.en);
    }
   

    const onSubmit = async (event) => {
        const isValid = await form.validate(event);

        if (isValid) {
            if(actForm === true){
                sendEmail(fields)
                setActAns(true)
                setOn(false)
                console.log(actAns)
                fields.email = ""   
                fields.name = ""
                fields.message= ""
                
            }else{
                
            }

        }else{

        }
    };

    useEffect(() => {
        if (form.isValidForm) {
            console.log("MAKE AN API CALL ==> useEffect", fields, errors, form);
        }
        
    }, []);

 

    return(
        <>
        <div className={on? "pop-up-modail" : "pop-up-modail pop-up-modail_off"}>
            <div className="form-size">
                <CrossButton></CrossButton>
                <div className="form-case">
                    <img alt="logo" src={LogoText} />
                    <h3>{t("form.title")}</h3>
                    <div className="dash"></div>
                    <form
                        className="form"
                        noValidate
                        autoComplete="off"
                        onSubmit={onSubmit}
                    >
                        <p  className="case-input">
                            <label>
                                {t("form.email")}
                                <input
                                    className={errors.email ? "error-input":""}
                                    type="email"
                                    name="email"
                                    placeholder={t('form.email-placeholder')}
                                    onBlur={form.handleBlurEvent}
                                    onChange={form.handleChangeEvent}
                                    value={fields.email}
                                    data-attribute-name={t("form.email")}
                                />
                            </label>
                            <label className={errors.email ? "error":""}>
                                <div></div>
                                <span>{errors.email ? errors.email : ""}</span>
                            </label>
                        </p>
                        <p className="case-input">
                            <label>
                                {t("form.name")}
                                <input
                                className={errors.name ? "error-input" : ""}
                                type="text"
                                name="name"
                                placeholder={t('form.name-placeholder')}
                                onBlur={form.handleBlurEvent}
                                onChange={form.handleChangeEvent}
                                value={fields.customer_name}
                                // To override the attribute name
                                data-attribute-name={t("form.name")}
                                data-async
                                />
                            </label>
                            <label className={errors.name ? "error":""}>
                                <div></div>
                                <span>{errors.name ? errors.name : ""}</span>
                            </label>
                        </p>
                        <p className="text-aria case-input">
                            <label>
                                <textarea
                                className={errors.message ? "error-input":""}
                                placeholder={t("form.text-aria")}
                                name="message"
                                noValidate
                                value={fields.message}
                                onChange={form.handleChangeEvent}
                                onBlur={form.handleBlurEvent}
                                data-attribute-name={t("form.text-aria")}
                                ></textarea>
                            </label>
                            <label className={errors.message ? "error":""}>
                                <div></div>
                                <span>{errors.message ? errors.message : ""}</span>
                            </label>
                        </p>
                        <div className={actReCa? "errCap cap" :"cap"}>
                            <ReCAPTCHA
                                sitekey="6LdknWYiAAAAAPqZVmi8c6YUAOviPwHSWUH3bHos"
                                onChange={()=>{setActForm(true)}}
                            />
                        </div>
                        <p>
                            <button className="button-form" type="submit" id="button-form">{t("form.button")}</button>
                        </p>
                    </form>
                </div>
                
                <img className="form-img" src={FormImg} alt="" />
            </div>
        </div>
        <div className={actAns? "ans-pop-up" :"ans-pop-up ans-pop-up__dis"}>
            <div className="little-cross" onClick={()=>{setActAns(false)}}>
                <div className="little-cross_top"></div>
                <div className="little-cross_bottom"></div>
            </div>
            <span>{t("form.ans")}</span>
        </div>
        </>
    )
}

