import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { MainApp } from './app/main-app/main-app';
import './index.scss'
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('wibsite-body'));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp></MainApp>}></Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

