import React from "react";
import { useTranslation } from 'react-i18next';
import { Icon } from "./icon/icon";
import { EasyAndFunct } from "./easy-and-functional/easy-and-functional";
import { ForDev } from "./for-developer/for-developer";

import "./content.scss"


export function Content(){
    const {t} = useTranslation();
    return(
        <div className="content case d-flex_column">
            <span className="content_title">{t('content.title')}</span>
            <div className="dash"></div>
            <Icon></Icon>
            <EasyAndFunct></EasyAndFunct>
            <ForDev></ForDev>
        </div>
    )
}

