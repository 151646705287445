import React, { useEffect, useState } from "react";
import { LanguageSwitcher } from "../button/lng-switch/lng-switch";
import { ButtonHeder } from "../button/pop-up-button/pop-up-button";

import "./header.scss"

import LogoText from "./img/lmachine-400px.png"
import Logo from "./img/lmachine-logo.svg"




export function HeaderBig(){
  const [logo,setLogo]=useState(LogoText)
  useEffect(()=>{
    const SizeH = ()=>{
      if(window.innerWidth >=540){
        setLogo(LogoText)
      }else{
        setLogo(Logo)
      }
    }

    window.addEventListener("resize",()=>{
      SizeH()
    })
    SizeH()
  })

  return(
    <header className="d-flex d-flex_column">
      <div className="case d-flex flex-jc-sb">
        <img src={logo} alt="logo" className="logo-header"/>
        <div className="d-flex flex-jc-sb nav">
          <LanguageSwitcher></LanguageSwitcher>
          <ButtonHeder></ButtonHeder>
        </div>
      </div>
    </header>
  )
}