import React from "react";
import { Content } from "../../componen/content/content";
import { Footer } from "../../componen/footer/footer";
import { HeaderBig } from "../../componen/header/header";
import { IntroContent } from "../../componen/intro-content/intro-content";
import { PopUpModailForm } from "../../componen/pop-up-modail/pop-up-modail";
import { WhyLmachine } from "../../componen/why-lmachine/why-lmachine";

export function MainApp(){
    return(
        <>
            <PopUpModailForm></PopUpModailForm>
            <HeaderBig></HeaderBig>
            <IntroContent></IntroContent>
            <Content></Content>
            <WhyLmachine></WhyLmachine>
            <Footer></Footer>
        </>
    )
}

