import React from "react";

import "./cross-button.scss"

export function CrossButton(){
    return(
        <div className="cross-button" id="cross-button">
            <div className="cross-button_top"></div>
            <div className="cross-button_botton"></div>
        </div>
    )
}