import React from "react";
import { useTranslation } from "react-i18next";

import P from "./img/puzzle_3.svg"
import I from "./img/intersect.svg"


import "./box-info.scss"

const ArrayIcon = [P,I]

export function BoxInfo(){
    const {t} = useTranslation();

    return(
        <div className="d-flex case-box-info">
            {
                ArrayIcon.map((el,index)=>{
                    return(
                        <div className="d-flex_column box-info" key={index}>
                            <div className="box-info_img">
                                <img className="d-flex_column" style={{width:42,height:42}} src={el} alt="" />
                            </div>
                            <h4>{t(`content.for-developer.case.${index}.0`)}</h4>
                            <h5 className={`box-info_text${index}`}>{t(`content.for-developer.case.${index}.1`)}</h5>
                        </div>
                    )
                })
            }
        </div>
    )
}