import React from "react";
import { useTranslation } from 'react-i18next';

import "./icon.scss"

import MB from "./img/money-bag.svg"
import P from "./img/people.svg"
import CB from "./img/cashback.svg"
import B from "./img/bonus.svg"
import L from "./img/love.svg"
import D from "./img/discount.svg"
import GC from "./img/gift-card_1.svg"
import A from "./img/awards_2.svg"

let ArrayIcon = [MB,P,CB,B,L,D,GC,A]

export function Icon(){
    const {t} = useTranslation()
    return(
        <div className="icon case">
            {
                ArrayIcon.map((el,index)=>{
                    return(
                        <div className="icon-case" key={index}>
                            <div className="icon-case_img d-flex_column">
                                <img src={el} alt={index} />
                            </div>
                            <span  className="icon-case_text">{t(`content.icon.${index}`)}</span>
                        </div>
                    )
                })
            }
        </div>
    )
}