import React from "react";
import { useTranslation } from "react-i18next";

import "./info-case.scss"

import P0 from "./img/puzzle.svg"
import NW from "./img/network.svg"
import P1 from "./img/puzzle_2.svg"
import HS from "./img/handshake_1.svg"
import  Logo from "./img/lmachine-logo.svg"

const ArrayIcon = [P0,NW,P1,HS]

export function InfoCase(){
    const {t} = useTranslation();
    return(
        <div className="info-case case d-flex ">
            <img alt="Content" src={Logo} className="info-case-logo"/>
            <div className="info-case_info ">
            {
                ArrayIcon.map((el,index)=>{
                    return(
                        <div className={`box${index}`} key={index}>
                            <div className="box_img">
                                <img src={el} alt="" />
                            </div>
                            <h3>{t(`why-lmachine.text-box.${index}.0`)}</h3>
                            <h5>{t(`why-lmachine.text-box.${index}.1`)}</h5>
                        </div>
                    )
                })
            }
            </div>
        </div>
    )
}