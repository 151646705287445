import React, { useEffect, useState } from "react";
// import ReactDOM from 'react-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./carouse.scss"

import UOF from "./img/_1.png";
import MP from "./img/_11.png";
import M from "./img/1-098.png";
import BC from "./img/111.png"
import P from "./img/Portmone_logosvg222.png"



// const ArrayCompaniLogo = [BC,MP,P,UOF,M]



const ResizeC = (set)=>{
    if(window.innerWidth >= 1165){
        set(4)
    }else if (window.innerWidth <= 1165 && window.innerWidth >= 850){
        set(3)
    }else if(window.innerWidth <= 850 && window.innerWidth >= 470){
        set(2)
    }else{
        set(1)
    }
}

export function CarouseApp(){
    const [box, setBox] = useState(4)
    useEffect(()=>{
        
        ResizeC(setBox)
       
        window.addEventListener("resize",()=>{
            ResizeC(setBox)
        })
    },[])

    let settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: box,
        slidesToScroll: 1,
        swipeToSlide:true,
        swipe:true,
    };
    return (
        <Slider {...settings} className="carouse">
            <div className="carouse_item">
                <img className="logo-compani" src={BC} alt="logo"/>
            </div>
            <div className="carouse_item" >
                <img className="logo-compani" src={MP} alt="logo"/>
            </div>                    
            <div className="carouse_item">
                <img className="logo-compani" src={P} alt="logo"/>
            </div>
            <div className="carouse_item" >
                <img className="logo-compani" src={UOF} alt="logo"/>
            </div>
            <div className="carouse_item" >
                <img className="logo-compani" src={M} alt="logo"/>
            </div>
        </Slider>
    )
}





