import React from "react"
import { useTranslation } from "react-i18next"
import { BoxInfo } from "./box-info/box-info"

import "./for-developer.scss"

import ContenImg from "./img/Group_1212.svg"
import Arrow from "./img/Group_853.svg"

export function ForDev(){
    const {t} = useTranslation()
    return(
        <div className="d-flex case for-dev ">
            <div className="text-box">
                <h2>{t("content.for-developer.subtitle")}</h2>
                <h1>{t("content.for-developer.title")}</h1>
                <div className="dash"></div>
                <h5>{t("content.for-developer.info")}</h5>
                <a href="https://documenter.getpostman.com/view/17957014/UVJbJHza#intro" >{t("content.for-developer.link")}<img src={Arrow} alt="" /></a>
                <BoxInfo></BoxInfo>
            </div>
            <img src={ContenImg} alt="content" className="for-dev_img-content"/>
        </div>
    )
}

