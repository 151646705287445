import React  from "react";
import { useTranslation } from "react-i18next";

import "./footer.scss"

export function Footer(){
    const {t} = useTranslation()
    return(
        <footer className="footer">
            <span>{t("footer.title")}</span>
        </footer>
    )
}