import React from "react";
import { useTranslation } from 'react-i18next';
import { ButtonContact } from "../button/pop-up-button/pop-up-button";
import { CarouseApp } from "./carouse/carouse";

import './intro-content.scss'

import ImgContent from "./img/Group_1167.svg"


export function IntroContent(){
    const { t } = useTranslation();
    return(
        <>
            <div className="bg-top"></div>
            <div className="intro-content case d-flex">
                <div className="intro-content_case case d-flex_column">
                    <span className="intro-content_title">{t('intro-content.title')}</span>
                    <span className="intro-content_info">{t('intro-content.info')}</span>
                    <ButtonContact></ButtonContact>
                </div>
                <img className="intro-content_img-content" src={ImgContent} alt="content" /> 
            </div>
            <CarouseApp></CarouseApp>
        </>
    )
}

