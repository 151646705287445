import emailjs from "@emailjs/browser";
import React from "react"

const SERVICE_ID = "emailjs_service_zoho";
const TEMPLATE_ID = "lm_tmp l_webform_submit";
const USER_ID = "k3zR-27mFbUNqDbWH";

emailjs.init(USER_ID);
export function sendEmail(form){

    emailjs.send(SERVICE_ID, TEMPLATE_ID, form, USER_ID)
    .then((result) => {
        console.log(result.text);
    }, (error) => {
        console.log(error.text);
    });
}